import * as React from "react";
import * as styles from '../page-styles/404.module.css';
import { Link } from "gatsby";

import Layout from "../components/Layout/Layout";

const NotFoundPage = ({location}) => {
  console.log(location);
  return (
    <Layout>
      <main>
        <section className={styles.container}>
        <h1>Page not found...</h1>
        <p><i><b>"{location.href}"</b></i> is not a page on this site. Try heading back to the homepage to get back on track.</p>
        <Link to='/' className={styles.homeButton}>Back to Home</Link>
        </section>
      </main>
    </Layout>
  )
}

export default NotFoundPage
