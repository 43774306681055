
import { Link } from 'gatsby';
import * as styles from './Navigation.module.css';
import logo from '../../images/mkr-official-logo-white.svg';
import {useState, useCallback} from 'react';
import * as React from 'react';
import Ruler from './Ruler/Ruler';

const Navigation = ({location}) => {
 const [navWrapperClasses, setNavWrapperClasses] = useState([styles.NavWrapper]);
 const [hamburgerClasses, setHamburgerClasses] = useState([styles.hamburger]);
 const [navItemsClasses, setNavItemsClasses] = useState([styles.navItems])

    const toggleMobileNav = ()=>{
            if(navWrapperClasses.length === 2){
                setNavWrapperClasses([styles.NavWrapper]);
            }
            else{
                setTimeout(()=>{
                    setNavWrapperClasses([styles.NavWrapper, styles.lockNav])
                }, 300)
            }
            setHamburgerClasses((prevState)=>{
                if(prevState.length === 2){
                    return [styles.hamburger]
                }
                else{
                    return [styles.hamburger, styles.transformBurger]
                }
            });
            setNavItemsClasses((prevState)=>{
                if(prevState.length === 2){
                    return [styles.navItems]
                }
                else{
                    return [styles.navItems, styles.show]
                }
            })    
    }
    const closeMobileNav = useCallback(()=>{
        setNavWrapperClasses([styles.NavWrapper]);
        setNavItemsClasses([styles.navItems]);
        setHamburgerClasses([styles.hamburger]);
   
    }, [])

    
    

    return (
        <div className={navWrapperClasses.join(" ")}>
            <Ruler/>
        <nav>
            <div className={styles.logo}>
                <Link to='/' onClick={closeMobileNav}>
                    <img className={styles.logoImage} src={logo} alt="logo" />
                </Link>
            </div>
            <div className={hamburgerClasses.join(" ")} onClick={toggleMobileNav}>
                <div className={styles.burgerLine1}></div>
                <div className={styles.burgerLine2}></div>
                <div className={styles.burgerLine3}></div>
                <p>MENU</p>
            </div>

            <ul className={navItemsClasses.join(" ")} >
                <li className={[styles.navItem, location === 'services' ? styles.active : ''].join(' ')}>
                    <Link to='/services/bathroom' onClick={closeMobileNav}>Services</Link>
                </li>
                <li className={[styles.navItem, location === 'work-request' ? styles.active : ''].join(' ')}>
                    <Link to='/work-request' onClick={closeMobileNav}>Work Request</Link>
                </li>
                <li className={[styles.navItem, location === 'about' ? styles.active : ''].join(' ')}>
                    <Link to='/about' onClick={closeMobileNav}>About</Link>
                </li>
                <li className={[styles.navItem, location === 'contact' ? styles.active : ''].join(' ')}>
                    <Link to='/contact' onClick={closeMobileNav}>Contact</Link>
                </li>
            </ul>
        </nav>
        </div>
    )
}

export default Navigation;

