import * as React from 'react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import BackToTop from '../ui/BackToTop/BackToTop'

const Layout = ({location, children})=>{
 
    return (
        <div>
            <Navigation location={location}/>
            {children}
            <BackToTop/>
            <Footer />
            
        </div>
    )
}

export default Layout;