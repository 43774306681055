import * as styles from './Footer.module.css';
import Ruler from '../Navigation/Ruler/Ruler';
import SocialLogo from '../ui/SocialLogo/SocialLogo';

import FacebookLogo from '../../images/facebook-logo-primary.png';
import InstagramLogo from '../../images/instagram-logo-primary.png';

import * as React from 'react';

const Footer = ()=>{
    return(
        <footer>
         
            <Ruler bottom={true}/>
            <div className={styles.footerBackground}>
               <div className={styles.socialLinks}>
                   <SocialLogo src={FacebookLogo} alt={'facebook'} href={'https://www.facebook.com/MichaelKolonayRenovations'}/>
                   <SocialLogo src={InstagramLogo} alt={'instagram'} href={'https://www.instagram.com/michael_kolonay_renovations/'}/>
              </div>
            </div>
                
            
        </footer>
    )
}

export default Footer;