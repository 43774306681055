import * as styles from './SocialLogo.module.css';
import * as React from 'react';

const SocialLogo = (props)=>{

    return(
        
            <a href={props.href} className={ styles.socialLogo} target='blank' rel="noopener noreferrer">
            <img src={props.src} alt={props.alt} />
            </a>
            
      
    )
}

export default SocialLogo;