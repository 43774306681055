// extracted by mini-css-extract-plugin
export var backToTop = "BackToTop-module--backToTop--bbfad";
export var backToTopButton = "BackToTop-module--backToTopButton--f2f2c";
export var invisible = "BackToTop-module--invisible--a547b";
export var visible = "BackToTop-module--visible--01e6b";
export var locked = "BackToTop-module--locked--485d8";
export var circle = "BackToTop-module--circle--89a0c";
export var horizontalArrowOne = "BackToTop-module--horizontalArrowOne--99279";
export var horizontalArrowTwo = "BackToTop-module--horizontalArrowTwo--b6b60";
export var text = "BackToTop-module--text--e2bb8";
export var fadeIn = "BackToTop-module--fadeIn--de5e6";
export var lockedText = "BackToTop-module--lockedText--a12b9";