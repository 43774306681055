import * as styles from './Ruler.module.css';
import * as React from 'react';

const Ruler = (props) => {
   
    let rulerLines = [];
    for (let num = 0; num <= 48; num++) {
        if (num % 4 === 0) {
            rulerLines.push(
                <div className={styles.rulerLineGroup} key={num}>
                    <div className={num === 0 || num === 48 ? styles.rulerLineEdge : styles.rulerLineLong}></div>
                    <p>{num !== 0 ? num / 4 : ''}</p>
                </div>)
        } else {
            rulerLines.push(
                <div className={styles.rulerLineGroup} key={num}>
                    <div className={styles.rulerLineShort}></div>
                </div>
            )
        }
    }
    return (
        <div className={props.bottom? styles.rulerBottom : styles.ruler}>
            {rulerLines}
        </div>
    )
}

export default Ruler;