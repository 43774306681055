import * as React from 'react';
import * as styles from './BackToTop.module.css';
import {useState, useEffect} from 'react';

const BackToTop = ()=>{
    const [visible, setVisible] = useState(false);
    const [locked, setLocked] = useState(false);
    let timeout = null;
    let prevScrollPosition = 0;

    const scrollToTop = ()=>{
        if(typeof window !== 'undefined'){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    }

    useEffect(()=>{
        if(typeof window !== 'undefined'){
        const handleVisibility = ()=>{
            clearTimeout(timeout);
            let scrollingUp = prevScrollPosition >= window.scrollY;
            prevScrollPosition = window.scrollY;
            if(scrollingUp){
                window.scrollY >= 1100 ? setVisible(true) : setVisible(false);
                window.innerHeight + window.scrollY >= document.body.offsetHeight ? setLocked(true) : setLocked(false);
            } else {
                timeout = setTimeout(()=>{
                    window.scrollY >= 1100 ? setVisible(true) : setVisible(false);
                    window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 ? setLocked(true) : setLocked(false);
                }, 20)
            }
        }
        window.addEventListener('scroll', handleVisibility)
        return ()=>{window.removeEventListener('scroll', handleVisibility)}
    }
    }, []);

    const backToTopClasses = [styles.backToTop];
    if(visible){
        backToTopClasses.push(styles.visible);
    }
    else {
        backToTopClasses.push(styles.invisible)
    }
    if(locked){
        backToTopClasses.push(styles.locked);
    }

    return(
        <div className={backToTopClasses.join(' ')} >
            <div className={styles.backToTopButton} onClick={scrollToTop}>
            <div className={styles.circle}>
                <div className={styles.horizontalArrowOne}></div>
                <div className={styles.horizontalArrowTwo}></div>
            </div>
            <p className={styles.text}>TO TOP</p>
            <p className={styles.lockedText}>Return to top</p>
            </div>
        </div>
    )
}

export default BackToTop;