// extracted by mini-css-extract-plugin
export var hamburger = "Navigation-module--hamburger--9a00d";
export var NavWrapper = "Navigation-module--NavWrapper--089d9";
export var logo = "Navigation-module--logo--c6d14";
export var logoImage = "Navigation-module--logoImage--3f931";
export var active = "Navigation-module--active--c8105";
export var lockNav = "Navigation-module--lockNav--6415a";
export var navItems = "Navigation-module--navItems--5b17f";
export var navItem = "Navigation-module--navItem--76356";
export var show = "Navigation-module--show--6c064";
export var transformBurger = "Navigation-module--transformBurger--286dc";
export var burgerLine1 = "Navigation-module--burgerLine1--be5ab";
export var burgerLine2 = "Navigation-module--burgerLine2--cebb8";
export var burgerLine3 = "Navigation-module--burgerLine3--269c5";